var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images
    ? _c(
        "div",
        { staticClass: "owl-carousel", attrs: { id: ["owl-" + _vm.unitid] } },
        _vm._l(_vm.images, function(img) {
          return _c("div", { staticClass: "item" }, [
            _c(
              "a",
              { attrs: { href: _vm.getUnitPath() } },
              [
                _c("img-fallback", {
                  attrs: {
                    img: img.id,
                    mode: "img",
                    "vo-msg:placeholder": "no.pic.placeholder"
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      )
    : _c(
        "div",
        [
          _c("img-fallback", {
            attrs: { mode: "img", "vo-msg:placeholder": "no.pic.placeholder" }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }