<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'owl-carousel',
    	props: {
    		carouselId: String,
    		items: {
    			type: String,
    			default: "1"
    		},
    		loop: {
    			type: String,
    			default: "false"
    		},
    		autoplay: {
    			type: String,
    			default: "false"
    		},
    		dots: {
    			type: String,
    			default: "false"
    		},
    		speed: {
    			type: String,
    			default: "3000"
    		},
    		nav: {
    			type: String,
    			default: "false"
    		},
    		slidemargin: {
    			type: String,
    			default: '0'
    		}
    	},

    	mounted: function() {

    		var itemsVal = parseInt(this.items);
    		var marginVal = parseInt(this.slidemargin);
    		var loopVal = (this.loop == 'true');
    		var autoplayVal = (this.autoplay == 'true');
    		var dotsVal = (this.dots == 'true');
    		var speedVal = parseInt(this.speed);
    		var navVal = (this.nav == 'true');
    		var selector = $("#owl-" + this.carouselId);

    		selector.owlCarousel({
    			items: itemsVal,
    			margin: marginVal,
    			loop: loopVal,
    			autoplay: autoplayVal,
    			dots: dotsVal,
    			smartSpeed: speedVal,
    			nav: navVal,
    			rewind: true,
    			autoplayTimeout: 10000,
    			onInitialized: function() {
    				selector.trigger('stop.owl.autoplay');
    				setTimeout(function() {
    					selector.trigger('play.owl.autoplay')
    				}, 5000)
    			},

    		});

    		/*function callBack(event) {
    			// Solution to have correct item number. See: https://github.com/OwlCarousel2/OwlCarousel2/issues/1029. Thanks to Modicrumb
    			var current = (event.item.index + 1) - event.relatedTarget._clones.length / 2;
    			var allItems = event.item.count;
    			if (current > allItems || current <= 0) {
    				current = allItems - (current % allItems);
    			}

    			if (current == 1) {
    				selector.trigger('stop.owl.autoplay')
    				setTimeout(function() {
    					selector.trigger('play.owl.autoplay')
    				}, 5000)
    			}
    		}*/
    	}
    };
</script>